var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.privacy')))]},proxy:true}])}),_c('div',{staticClass:"mb-3"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',{staticClass:"product-container"},[_c('div',{staticClass:"col-12"},[_c('b-tabs',{attrs:{"fill":""}},[_c('div',{staticClass:"d-flex"},[_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.app')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.app'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.merchantOptions,"placeholder":_vm.$t('choose.merchant'),"searchable":false,"label":"name"},model:{value:(_vm.dataForModal.merchant),callback:function ($$v) {_vm.$set(_vm.dataForModal, "merchant", $$v)},expression:"dataForModal.merchant"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.type')))]),_c('ValidationProvider',{attrs:{"name":("" + (_vm.$t('titles.type'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":("" + (_vm.$t('enter.type'))),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-tab',{attrs:{"active":"","title":_vm.$t('titles.privacy') + ' ' + '[UZ]'}},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.title.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "uz", $$v)},expression:"dataForModal.title.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.dataForModal.content_uz),callback:function ($$v) {_vm.$set(_vm.dataForModal, "content_uz", $$v)},expression:"dataForModal.content_uz"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('titles.privacy') + ' ' + '[RU]'}},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.title.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "ru", $$v)},expression:"dataForModal.title.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.dataForModal.content_ru),callback:function ($$v) {_vm.$set(_vm.dataForModal, "content_ru", $$v)},expression:"dataForModal.content_ru"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('titles.privacy') + ' ' + '[EN]'}},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.title.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "en", $$v)},expression:"dataForModal.title.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption,"placeholder":"asd"},model:{value:(_vm.dataForModal.content_en),callback:function ($$v) {_vm.$set(_vm.dataForModal, "content_en", $$v)},expression:"dataForModal.content_en"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(_vm._s(_vm.$t('button.save')))])],1)])],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }