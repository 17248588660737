<script>
import api from '@/services/api';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { clearObjectValues, copyObjectWithoutID } from '@/util/helper';
import vSelect from 'vue-select';
import { BCol, BFormGroup, BFormInput, BRow, BTab, BTabs } from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle.vue';
import PasteImageFileAgent from '@/mixins/pasteImageFileAgent';

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';

export default {
    name: 'MarketPolicyCRUD',
    mixins: [CRUDPageMixin, PasteImageFileAgent],
    components: {
        PageTitle,
        vSelect,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BTabs,
        BTab,
        quillEditor
    },

    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ align: [] }],
                        ['clean']
                    ]
                }
            },
            items: [],
            merchantOptions: [],
            dataForModal: {
                title: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                content_uz: '',
                content_en: '',
                content_ru: '',
                merchant: null,
                type: ''
            },
            isUpdatingPage: false,
            updatingItem: null,
            requestPending: false
        };
    },

    async mounted() {
        this.requestPending = true;
        await this.getMerchants();
        const params = this.$route.params;
        if (params?.id) {
            await this.fetchMarketPolicy(params.id);
            this.isUpdatingPage = true;
        }
        this.requestPending = false;
    },

    methods: {
        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchMarketPolicy(id) {
            const body = {
                id: id
            };
            await api.policy
                .fetchOneMarketPolicy(body)
                .then((response) => {
                    const data = response.data.result;
                    this.openModalToUpdate(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        openModalToUpdate(item) {
            this.dataForModal = copyObjectWithoutID(item);
            this.updatingItem = item;
            Object.keys(this.updatingItem).forEach((key) => {
                if (key === 'role') {
                    this.dataForModal.role_id = this.dataForModal.role?.id;
                    delete this.dataForModal.role;
                }

                if (key === 'input_type') {
                    if (this.dataForModal.input_type?.id)
                        this.dataForModal.input_type_id = this.dataForModal.input_type.id;
                    delete this.dataForModal.input_type;
                }

                if (key === 'dependent_survey') {
                    if (this.dataForModal.dependent_survey?.uuid)
                        this.dataForModal.dependent_survey_id =
                            this.dataForModal.dependent_survey.uuid;
                    delete this.dataForModal.dependent_survey;
                }

                if (key === 'gifts') {
                    this.dataForModal.gift_id = this.dataForModal.gifts.map((gift) => {
                        return (gift = gift.id);
                    });
                    delete this.dataForModal.gifts;
                }

                if (key === 'level') {
                    this.dataForModal.level_id = this.dataForModal.level?.id;
                    delete this.dataForModal.level;
                }

                if (key === 'warehouse') {
                    if (this.warehouseOptions) {
                        const idx = this.warehouseOptions.findIndex(
                            (item) => item.id === this.dataForModal?.warehouse?.id
                        );
                        if (idx !== -1) {
                            this.dataForModal.merchant = this.warehouseOptions[idx];
                            delete this.dataForModal.warehouse_id;
                        }
                    }
                }

                if (key === 'category') {
                    if (this.categoryOptions) {
                        const idx = this.categoryOptions.findIndex(
                            (item) => item.id === this.dataForModal?.category?.id
                        );
                        if (idx !== -1) {
                            this.dataForModal.category = this.categoryOptions[idx];
                            delete this.dataForModal.category_id;
                        }
                    }
                }

                if (key === 'image') {
                    this.dataForModal.image;
                    // this.dataForModal.image_id = this.dataForModal.image?.id
                    // delete this.dataForModal.image
                }

                if (key === 'buttons' && !this.updatingItem[key]) {
                    this.dataForModal.buttons = {
                        name: {
                            uz: '',
                            ru: '',
                            en: ''
                        },
                        link: ''
                    };
                }

                if (key === 'measurement' && typeof this.dataForModal.measurement === 'object') {
                    this.dataForModal.measurement = {
                        id: this.dataForModal.measurement?.id,
                        name: this.checkLocales(this.dataForModal.measurement?.name)
                    };
                }

                if (key === 'images') {
                    for (let i = 0; i < this.dataForModal.images.length; i++) {
                        const image = this.dataForModal.images[i];
                        this.dataForModal.images[i] = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }
                }
            });
            this.isOpenModal = true;
        },

        async createMarketPolicy(body) {
            await api.policy
                .createMarketPolicy(body)
                .then(async () => {
                    this.$toast.success(this.$t('success.added'));
                    if (this.createOneMore) {
                        setTimeout(() => {
                            location.reload();
                        }, 500);
                    } else {
                        await this.$router.push({ name: 'market-policy' });
                    }
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateMarketPolicy(body, config) {
            await api.policy
                .updateMarketPolicy(body, config)
                .then(async () => {
                    this.$toast.success(this.$t('success.updated.title'));
                    await this.$router.push({ name: 'market-policy' });
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    this.dataForModal['merchant_id'] = this.dataForModal.merchant.id;
                    delete this.dataForModal.merchant;

                    let body = this.dataForModal;
                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateMarketPolicy(body, config);
                        } else {
                            await this.createMarketPolicy(body, config);
                        }
                    } catch (error) {
                        this.$toast.error(
                            error.response?.data?.message || error.message || this.$t('error.smth')
                        );
                    }
                }
            });
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.privacy') }}</template>
        </page-title>

        <div class="mb-3">
            <b-row>
                <b-col cols="12">
                    <ValidationObserver ref="createUpdateModal">
                        <!--  PRODUCT NAME  -->
                        <b-row class="product-container">
                            <div class="col-12">
                                <b-tabs fill>
                                    <div class="d-flex">
                                        <!--     MERCHANT     -->
                                        <b-col cols="6">
                                            <label>{{ $t('titles.app') }}</label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="$t('titles.app')"
                                                rules="required"
                                            >
                                                <v-select
                                                    v-model="dataForModal.merchant"
                                                    :options="merchantOptions"
                                                    :placeholder="$t('choose.merchant')"
                                                    :searchable="false"
                                                    label="name"
                                                />
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <!--    TYPE    -->
                                        <b-col cols="6">
                                            <label>{{ $t('titles.type') }}</label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.type')}`"
                                                rules="required"
                                            >
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.type"
                                                        :placeholder="`${$t('enter.type')}`"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="md"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">
                                                    {{ errors[0] }}
                                                </span>
                                            </ValidationProvider>
                                        </b-col>
                                    </div>

                                    <!--   UZ   -->
                                    <b-tab active :title="$t('titles.privacy') + ' ' + '[UZ]'">
                                        <!--    NAME[UZ]    -->
                                        <b-col cols="12">
                                            <label> {{ $t('titles.title') }} [UZ] </label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.title')} [UZ]`"
                                                rules="required"
                                            >
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.title.uz"
                                                        :placeholder="`${$t('enter.title')} [UZ]`"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="md"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">
                                                    {{ errors[0] }}
                                                </span>
                                            </ValidationProvider>
                                        </b-col>

                                        <!--    DESCRIPTION[UZ]    -->
                                        <b-col cols="12">
                                            <label> {{ $t('titles.description') }} [UZ] </label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.description')} [UZ]`"
                                                rules="required"
                                            >
                                                <quill-editor
                                                    v-model="dataForModal.content_uz"
                                                    :options="editorOption"
                                                ></quill-editor>
                                                <span class="validation__red">
                                                    {{ errors[0] }}
                                                </span>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-tab>

                                    <b-tab :title="$t('titles.privacy') + ' ' + '[RU]'">
                                        <!--    NAME[RU]    -->
                                        <b-col cols="12">
                                            <label> {{ $t('titles.title') }} [RU] </label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.title')} [RU]`"
                                                rules="required"
                                            >
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.title.ru"
                                                        :placeholder="`${$t('enter.title')} [RU]`"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="md"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <!--    DESCRIPTION[RU]    -->
                                        <b-col cols="12">
                                            <label> {{ $t('titles.description') }} [RU] </label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.description')} [RU]`"
                                                rules="required"
                                            >
                                                <quill-editor
                                                    v-model="dataForModal.content_ru"
                                                    :options="editorOption"
                                                ></quill-editor>
                                                <span class="validation__red">
                                                    {{ errors[0] }}
                                                </span>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-tab>

                                    <b-tab :title="$t('titles.privacy') + ' ' + '[EN]'">
                                        <!--    NAME[EN]    -->
                                        <b-col cols="12">
                                            <label> {{ $t('titles.title') }} [EN] </label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.title')} [EN]`"
                                                rules="required"
                                            >
                                                <b-form-group>
                                                    <b-form-input
                                                        v-model="dataForModal.title.en"
                                                        :placeholder="`${$t('enter.title')} [EN]`"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="md"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <!--    DESCRIPTION[EN]    -->
                                        <b-col cols="12">
                                            <label> {{ $t('titles.description') }} [EN] </label>
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="`${$t('titles.description')} [EN]`"
                                                rules="required"
                                            >
                                                <quill-editor
                                                    v-model="dataForModal.content_en"
                                                    :options="editorOption"
                                                    placeholder="asd"
                                                ></quill-editor>
                                                <span class="validation__red">
                                                    {{ errors[0] }}
                                                </span>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-tab>

                                    <b-col cols="12">
                                        <div class="float-right">
                                            <b-button variant="success" @click="submitModal">{{
                                                $t('button.save')
                                            }}</b-button>
                                        </div>
                                    </b-col>
                                </b-tabs>
                            </div>
                        </b-row>
                    </ValidationObserver>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.product-container__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    color: #4b465c;
}

.product-container {
    display: flex;
    margin: 0;
    row-gap: 1rem;
    padding: 2rem 1rem;
    border: 1px solid #dbdade;
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.v-select {
    background-color: #fff;
}
</style>
